body {
  background-color: #00101b;
  color: white;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Super Saiyan Aura Animation */
.aura-container {
  position: relative;
  background: radial-gradient(circle, rgba(255, 255, 0, 0.6) 0%, rgba(255, 255, 0, 0) 70%);
  box-shadow: 0 0 30px 20px rgba(255, 255, 0, 0.4);
  animation: pulsate 1.5s infinite ease-in-out;
}

.aura-container::before {
  content: '';
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  background: radial-gradient(circle, rgba(255, 255, 0, 0.8) 10%, transparent 70%);
  border-radius: 50%;
  box-shadow: 0 0 60px 40px rgba(255, 255, 0, 0.5), 0 0 100px 80px rgba(255, 255, 0, 0.3);
  animation: auraGlow 2.5s infinite ease-in-out;
}

/* Flames coming out effect */
.aura-container::after {
  content: '';
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  background: radial-gradient(circle, rgba(255, 255, 0, 0.6) 10%, transparent 80%);
  border-radius: 50%;
  box-shadow: 0 0 80px 40px rgba(255, 165, 0, 0.7), 0 0 120px 100px rgba(255, 69, 0, 0.5);
  animation: flameFlicker 1.2s infinite ease-in-out;
  opacity: 0.8;
}

/* Pulsating Aura Animation */
@keyframes pulsate {
  0% {
    box-shadow: 0 0 30px 20px rgba(255, 255, 0, 0.4);
  }
  50% {
    box-shadow: 0 0 40px 30px rgba(255, 255, 0, 0.6);
  }
  100% {
    box-shadow: 0 0 30px 20px rgba(255, 255, 0, 0.4);
  }
}

/* Glowing Aura Animation */
@keyframes auraGlow {
  0% {
    box-shadow: 0 0 60px 40px rgba(255, 255, 0, 0.5), 0 0 100px 80px rgba(255, 255, 0, 0.3);
  }
  50% {
    box-shadow: 0 0 80px 50px rgba(255, 255, 0, 0.7), 0 0 120px 100px rgba(255, 255, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 60px 40px rgba(255, 255, 0, 0.5), 0 0 100px 80px rgba(255, 255, 0, 0.3);
  }
}

/* Flame Flickering Animation */
@keyframes flameFlicker {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
}
/* Custom select styles */
.custom-select {
  position: relative;
  padding-right: 30px; /* Adjust space for the arrow */
}

/* Remove default arrow */
.custom-select::-ms-expand {
  display: none; /* Hide the default dropdown arrow for IE */
}

.custom-select {
  -webkit-appearance: none; /* Hide the default arrow in WebKit browsers */
  -moz-appearance: none; /* Hide the default arrow in Firefox */
  appearance: none; /* Hide the default arrow in modern browsers */
}

/* Add custom arrow */
.custom-select::after {
  content: '▼'; /* Unicode character for the down arrow */
  position: absolute;
  right: 12px; /* Adjust this to fine-tune the arrow's position */
  top: 50%;
  transform: translateY(-50%);
  color: #fff; /* Arrow color */
  font-size: 14px; /* Adjust the size of the arrow */
  pointer-events: none; /* Prevent the arrow from blocking interactions */
}
