.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.skeleton {
  background-color: #e0e0e0;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.1;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Style the scrollbar track */
::-webkit-scrollbar {
  width: 8px; /* Adjust scrollbar width */
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #162736; /* Gray color */
  border-radius: 10px; /* Rounded edges */
}

/* Style on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #1e324b; /* Slightly darker gray on hover */
}

/* Optional: Style the scrollbar track */
::-webkit-scrollbar-track {
  background-color: #1f304a; /* Lighter background for contrast */
  border-radius: 10px;
}