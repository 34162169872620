.background-animation {
  position: relative;
  overflow: hidden;
  will-change: background-position;
  min-height: 100vh;
}

.background-animation::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
  background-size: 50px 50px;
  background-image: radial-gradient(
    circle,
    rgba(255, 0, 0, 0.3) 4px,
    transparent 4px
  );
  animation: moveDotsCircular 2s linear infinite, rainbowGlow 2s linear infinite;
  z-index: -1;
}

@keyframes rainbowGlow {
  0% {
    filter: hue-rotate(0deg);
  }
  16.67% {
    filter: hue-rotate(60deg);
  }
  33.33% {
    filter: hue-rotate(120deg);
  }
  50% {
    filter: hue-rotate(180deg);
  }
  66.67% {
    filter: hue-rotate(240deg);
  }
  83.33% {
    filter: hue-rotate(300deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

/* SHAKE TAT ASSS ---------------------------------------------------------------*/
.shake {
  animation: shake 0.35s;
  transform-origin: center;
}

@keyframes shake {
  0% {
    transform: translate(0) rotate(0deg);
  }
  25% {
    transform: translate(-40px, 0) rotate(-10deg);
  }
  50% {
    transform: translate(40px, 0) rotate(10deg);
  }
  75% {
    transform: translate(-40px, 0) rotate(-10deg);
  }
  100% {
    transform: translate(0) rotate(0deg);
  }
}

/* RAIN HEARTS EFFECT ----------------------------------------------------------*/

.rain-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200vh;
  pointer-events: none;
  overflow: hidden;
  z-index: -1;
}

.rain-gif {
  position: sticky;
  top: -100vh;
  width: 50px;
  height: auto;
  animation: fall linear infinite;
  opacity: 0.8;
}

@keyframes fall {
  to {
    transform: translateY(calc(100vh + 10%));
  }
}

/* sparkle --------------------------------------------------------- */

.sparkle-gif {
  height: 100px;
  width: 100px;
  margin-top: 70px;
}

.card {
  background-color: rgba(5, 28, 51, 0.25);
  backdrop-filter: blur(2px);
  border-radius: 10px;
  border: #355c82 solid 5px;
  height: 110%;
}

.etherfun-gif {
  width: 450px;
  animation: twitch 0.1s infinite alternate, infinite alternate;
}
@keyframes twitch {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(-2%, 2%) rotate(-1deg);
  }
  100% {
    transform: translate(0, 0) rotate(1deg);
  }
}

.etherfuntitle {
  color: red;
  font-weight: bold;
  transform: translateY(8px);
}

.chat-container {
  position: absolute;
  top: 40px;
  right: 50px;
  padding: 0 0 0 15px;
  height: 270px;
  background-color: rgba(5, 28, 51, 0.25);
  backdrop-filter: blur(2px);
  border-radius: 10px;
  width: 400px;
  margin: 25px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 10;
}
.messages {
  padding-left: 15px;
  height: 300px;
  width: 100%;
  text-align: left;
  overflow-x: hidden;
}

.chat-container .messages.scrollable {
  max-height: 365px;
  overflow-y: auto;
  background-color: transparent;

  scrollbar-width: thin;
  scrollbar-color: #355c82 transparent;
}

.chat-container .messages.scrollable::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}

.chat-container .messages.scrollable::-webkit-scrollbar-thumb {
  background-color: #111139;
  border-radius: 10px;
}

.chat-container .messages.scrollable::-webkit-scrollbar-track {
  background: #111139;
}
@media (max-width: 1300px) {
  .chat-container {
    display: none;
  }
}
.messageform {
  margin: 0 auto 9px auto;
  width: 100%;
  height: 25px;
}
.sendbutton {
  margin-left: 25px;
}

.inputmsg {
  border: transparent 1px;
  border-radius: 5px;
  padding: 0 5px;
  width: 75px;
}

.anonymous {
  margin: 0 0 15px 50%;
  transform: translateX(-50%);
  background-color: #355c82;
  padding: 5px;
  width: 250px;
  border-radius: 5px;
}

/* LAUNCH PAGE -----------------------------------------------------------*/

.launchinput {
  background-color: rgba(5, 28, 51, 0.25);
  backdrop-filter: blur(2px);
  border-radius: 10px;
  border: #355c82 solid 5px;
  width: 352px;
  height: 42px;
  padding-left: 3px;
  outline: none;
  color: #ffffff; /* Set text color explicitly */
  font-size: 16px; /* Optional: to standardize text size */
}
.launchinputdesc {
  background-color: rgba(5, 28, 51, 0.25);
  backdrop-filter: blur(2px);
  border-radius: 10px;
  border: #355c82 solid 5px;
  width: 352px;
  height: 84px;
  padding-left: 3px;
  outline: none;
}

.skelly {
  position: fixed;
  bottom: 0;
  right: 50px;
  z-index: -1;
}

.empty {
  margin-top: 50px;
  font-size: 25px;
  font-weight: bold;
}

.sparkle-launch {
  height: 50px;
  width: 50px;
  margin: 50px 25px 0 25px;
}

@media (max-width: 550px) {
  .skelly {
    width: 50%;
  }
}
/* SWIPER TOP ------------------------------------------------------*/

.wiggle {
  animation: wiggle 0.3s ease-in-out;
}

@keyframes wiggle {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-15px, 0);
  }
  50% {
    transform: translate(15px, 0);
  }
  75% {
    transform: translate(-15px, 0);
  }
  100% {
    transform: translate(0);
  }
}

.button {
  width: 40px; /* Adjust width for circular appearance */
  height: 40px; /* Adjust height for circular appearance */
  display: flex;
  align-items: center;
  justify-content: center;
}
