.popupsContent {
    position: fixed;
    bottom: 20px;
    right: 20px;
    color: #ffffff;
    font-size: 14px;
    opacity: 1;
    animation: fadeOut 5s ease-in-out forwards;
  }
.popups{
  background-color: #cf4753;
  margin-top: 13px;
  padding: 16px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.popupsMessage{
  background-color: #262c36;
  margin-top: 13px;
  padding: 16px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  